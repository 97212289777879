.menu_main4 {
  height: 100%;
  width: 0%;
  position: absolute;
  padding: 0px;
  z-index: 100;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  z-index: 100;
}

.menu_main4_bg3 {
  height: 200%;
  width: 100%;
  position: absolute;
  padding: 0px;
  background-color: #ffe0dc;
  z-index: 100;
  overflow: hidden;
}

.menu_main4_bg4 {
  height: 200%;
  width: 100%;
  position: absolute;
  padding: 0px;
  background-color: #7d938a;
  z-index: 101;
  overflow: hidden;
}

#trans6 {
  color: #7d938a;
}
