.menu_main4 {
  height: 100%;
  width: 0%;
  z-index: 100;
  z-index: 100;
  flex-direction: column;
  place-content: center;
  padding: 0;
  position: absolute;
}

.menu_main4_bg3 {
  height: 200%;
  width: 100%;
  z-index: 100;
  background-color: #ffe0dc;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.menu_main4_bg4 {
  height: 200%;
  width: 100%;
  z-index: 101;
  background-color: #7d938a;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

#trans6 {
  color: #7d938a;
}

/*# sourceMappingURL=index.121aebbb.css.map */
